/********** Header sticky **********/

.navik-header {
  &.sticky {
    position: fixed;
    width: 100%;
    animation-name: stickyTransition;
    animation-duration: $sticky-menu-animation-duration;
    animation-fill-mode: both;

    .navik-header-container {
      height: $sticky-menu-height;
    }

    .navik-menu {
      > ul {
        > li {
          > a {
            height: $sticky-menu-height;
            line-height: $sticky-menu-height;
          }
        }
      }
    }

    .logo {
      padding: $sticky-menu-logo-padding;

      img {
        max-height: $sticky-menu-height;
      }
    }
  }
}

@keyframes stickyTransition {
  from {
    opacity: 0;
    @include transform-prefix("translate3d", $sticky-menu-translate);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

/***************************************************
  Center menu
****************************************************/

.logoCenter {
  display: none;
}

.center-menu-1 {
  .navik-menu {
    > ul {
      &:first-child {
        padding: 0;
      }
    }
  }
}
